<template>
  <div>
    <vs-row class="mt-4">
      <vs-col
        v-for="(totalincome, index) in totalincomes"
        :key="index"
        vs-xs="12"
        :class="[ totalincome.gridlg, totalincome.gridsm ]"
      >
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <span
              class="display-4"
              :class="[ totalincome.iconcolor ]"
            >
              <i :class="[ totalincome.icon]" />
            </span>
          </div>
          <div>
            <span :class="[ totalincome.textcolor ]">{{ totalincome.text }}</span>
            <h3 class="font-medium mb-0">
              {{ totalincome.money }}
            </h3>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  name: 'MonthlyProfitList',
  components: {},
  props: {
    totalincomes: {
      type: Array,
      required: true
    }
  }
};
</script>